import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { UserResolver } from "@core/resolvers/user-resolver";
import { NotFoundPageComponent } from "@feature/error/pages/not-found-page.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "plan",
    pathMatch: "full",
  },
  {
    path: "bekijkplanning/:planId",
    redirectTo: "plan/:planId",
    pathMatch: "full",
  },
  {
    path: "session",
    loadChildren: () => import("@feature/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "plan",
    loadChildren: () => import("@feature/plan/plan.module").then((m) => m.PlanModule),
    resolve: {
      user: UserResolver,
    },
  },
  {
    path: "catalog",
    loadChildren: () => import("@feature/catalog/catalog.module").then((m) => m.CatalogModule),
    resolve: {
      user: UserResolver,
    },
  },
  {
    path: "curriculum",
    loadChildren: () => import("@feature/curriculum/curriculum.module").then((m) => m.CurriculumModule),
    resolve: {
      user: UserResolver,
    },
  },
  {
    path: "theme",
    loadChildren: () => import("@feature/theme/theme.module").then((m) => m.ThemeModule),
  },
  {
    path: "error",
    loadChildren: () => import("@feature/error/error.module").then((m) => m.ErrorModule),
  },
  {
    path: "**",
    component: NotFoundPageComponent,
    data: {
      status: 404,
      message: "Page not found",
    },
  },
];

@NgModule({
  imports: [
    //
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, bindToComponentInputs: true }),
  ],
  exports: [
    //
    RouterModule,
  ],
  // providers: [provideRouter(routes, { } ],
})
export class AppRoutingModule {}
