import { Component, inject } from "@angular/core";
import { NavController } from "@ionic/angular/standalone";
import { MainTabButtonComponent } from "../main-tab-button/main-tab-button.component";

@Component({
  selector: "pa-main-tab-bar",
  templateUrl: "./main-tab-bar.component.html",
  styleUrls: ["./main-tab-bar.component.scss"],
  standalone: true,
  imports: [MainTabButtonComponent],
})
export class MainTabBarComponent {
  private nav = inject(NavController);

  activeTab = "plan";

  onSelectTab(tabName: string): void {
    this.activeTab = tabName;
    this.nav.navigateRoot(`/${tabName}`);
  }
}
