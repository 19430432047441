import { Component, Input, OnInit } from "@angular/core";
import { IonNote } from "@ionic/angular/standalone";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "pa-main-tab-button",
  templateUrl: "./main-tab-button.component.html",
  styleUrls: ["./main-tab-button.component.scss"],
  standalone: true,
  imports: [TranslateModule, IonNote],
})
export class MainTabButtonComponent implements OnInit {
  @Input() active = false;
  @Input() type: "plan" | "catalog" | "curriculum" | undefined;

  name = "";

  constructor() {}

  ngOnInit(): void {
    switch (this.type) {
      case "plan":
        this.name = "label.plan";
        break;
      case "catalog":
        this.name = "label.catalog";
        break;
      case "curriculum":
        this.name = "label.curriculum";
        break;
    }
  }
}
