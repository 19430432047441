import { CommonModule } from "@angular/common";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { AppHeaderComponent } from "@core/components/header/app-header/app-header.component";
import { MainTabBarComponent } from "@core/components/header/main-tab-bar/main-tab-bar.component";
import { MainTabButtonComponent } from "@core/components/header/main-tab-button/main-tab-button.component";
import { GlobalErrorHandler } from "@core/error/global-error-handler";
import { TranslateModule } from "@ngx-translate/core";

import { NgxPermissionsModule } from "ngx-permissions";

@NgModule({
  imports: [
    //
    CommonModule,
    FormsModule,
    RouterLink,
    TranslateModule,
    RouterLinkActive,
    NgxPermissionsModule,
    //
    AppHeaderComponent,
    MainTabBarComponent,
    MainTabButtonComponent,
  ],
  exports: [
    //
    AppHeaderComponent,
    MainTabBarComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
})
export class CoreModule {}
