import { inject, Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular/standalone";
import { TranslateService } from "@ngx-translate/core";
import { LoadingService } from "@shared/services/loading-service";

@Injectable({
  providedIn: "root",
})
export class ErrorDialogService {
  private opened = false;

  alertController = inject(AlertController);
  translate = inject(TranslateService);
  loadingService = inject(LoadingService);

  async openErrorDialog(subTitle?: string, message?: string): Promise<void> {
    if (!this.opened) {
      // Close the loading spinner (should it be present)
      this.loadingService.dismiss();

      this.opened = true;
      const alert = await this.alertController.create({
        header: this.translate.instant("error.generic.title"),
        subHeader: subTitle,
        message: message ?? this.translate.instant("error.unexpectedError"),
        buttons: [this.translate.instant("label.ok")],
        backdropDismiss: false,
      });

      await alert.present();
    }
  }
}
