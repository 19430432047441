import { Component, inject, OnInit } from "@angular/core";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from "@angular/router";
import { AppHeaderComponent } from "@core/components/header/app-header/app-header.component";
import { User } from "@core/domain/user";
import { AuthService } from "@core/services/auth.service";
import { VersionService } from "@core/services/version.service";
import { environment } from "@environment/environment";
import { IonApp, IonContent, IonHeader, IonRouterOutlet } from "@ionic/angular/standalone";
import { LoadingService } from "@shared/services/loading-service";
import { addIcons } from "ionicons";
import {
  addOutline,
  alertCircleOutline,
  arrowRedo,
  arrowUndo,
  attach,
  chevronDownOutline,
  close,
  closeOutline,
  ellipsisVertical,
  informationCircle,
  informationCircleOutline,
  link,
  logInOutline,
  move,
  removeCircle,
  search,
  swapVertical,
  trash,
  trashOutline,
} from "ionicons/icons";
import { filter } from "rxjs";

@Component({
  selector: "pa-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  standalone: true,
  imports: [AppHeaderComponent, IonApp, IonHeader, IonContent, IonRouterOutlet],
})
export class AppComponent implements OnInit {
  user: User | undefined;
  private authService = inject(AuthService);
  private router = inject(Router);
  private loadingService = inject(LoadingService);
  private versionService = inject(VersionService);

  ngOnInit(): void {
    if (environment.name !== "PROD") console.log(`Running env '${environment.name}'`);

    this.router.events
      .pipe(filter((event: unknown): event is RouterEvent => event instanceof RouterEvent))
      .subscribe((routerEvent: RouterEvent) => {
        switch (true) {
          case routerEvent instanceof NavigationStart: {
            this.loadingService.present();
            break;
          }

          case routerEvent instanceof NavigationEnd:
          case routerEvent instanceof NavigationCancel:
          case routerEvent instanceof NavigationError: {
            this.loadingService.dismiss();
            break;
          }

          default: {
            break;
          }
        }
      });

    this.authService.getUser$().subscribe((user) => {
      this.user = user;
    });

    this.versionService.setOsirisVersion();

    this.loadIcons();
  }

  private loadIcons() {
    addIcons({
      addOutline,
      alertCircleOutline,
      arrowUndo,
      arrowRedo,
      attach,
      chevronDownOutline,
      close,
      closeOutline,
      ellipsisVertical,
      informationCircle,
      informationCircleOutline,
      link,
      logInOutline,
      move,
      removeCircle,
      search,
      swapVertical,
      trash,
      trashOutline,
    });
  }
}
