import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone, inject } from "@angular/core";
import { Router } from "@angular/router";
import { ErrorDialogService } from "@core/error/error-dialog-service";
import { TokenService } from "../services/token.service";

@Injectable({
  providedIn: "root",
})
export class GlobalErrorHandler implements ErrorHandler {
  private errorDialogService = inject(ErrorDialogService);
  private router = inject(Router);
  private zone = inject(NgZone);
  private tokenService = inject(TokenService);

  handleError(error: Error): void {
    console.error("Error occurred:", error);

    // Check if it's an error from an HTTP response
    if (error instanceof HttpErrorResponse) {
      if (this.isNotFoundError(error.status)) {
        this.gotoNotFoundPage(error.error, error.message, error.status);
      } else if (this.isBigError(error.status)) {
        this.gotoErrorPage(error.error, error.message, error.status);
      }
    } else {
      // Generic error, probably some error was thrown in the application
      this.showGenericErrorDialog();
    }
  }

  handleRejectedDatabaseLogin(): void {
    // Auth server login was successful, but the database does not accept this user
    // and caused a logout in the auth server.
    // The current url includes a status_meldingen param.
    // Now we must show the user the status message and clear the token data to reflect the logout.
    this.tokenService.removeAllTokenData();
    const currentUrl = window.location.href; // http://localhost:4200/?status_meldingen=Je+bent+niet+geregistreerd+voor+OSIRIS.
    let statusMessage: string = "";

    // Check if the URL contains a query string
    if (currentUrl.includes("?")) {
      const urlParams = new URLSearchParams(currentUrl.split("?")[1]);
      statusMessage = urlParams.get("status_meldingen") as string;
    }

    // Check if the URL contains a fragment (can happen with the legacy auth server)
    if (!statusMessage && currentUrl.includes("#")) {
      const hashParams = new URLSearchParams(currentUrl.split("#")[1]);
      statusMessage = hashParams.get("status_meldingen") as string;
    }
    this.gotoErrorPage(statusMessage, statusMessage, 401);
  }

  private isBigError(status: number): boolean {
    return (status > 399 && status < 500 && status !== 404) || (status > 499 && status < 600);
  }

  private isNotFoundError(status: number): boolean {
    return status === 404;
  }

  private gotoNotFoundPage(message: string, details: string, status: number): void {
    this.zone.run(() => {
      this.router.navigateByUrl("/error/notfound", {
        state: {
          status,
          message,
          details,
        },
      });
    });
  }

  private gotoErrorPage(message: string, details: string, status: number): void {
    this.zone.run(() => {
      this.router.navigateByUrl("/error", {
        state: {
          status,
          message,
          details,
        },
      });
    });
  }

  private showGenericErrorDialog(): void {
    this.zone.run(() => {
      this.errorDialogService.openErrorDialog();
    });
  }
}
